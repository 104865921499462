
export class Config {

    static organizationId = '60f0590c57d8a961f4639b44';
    static organizationName = "Indian Aroma Restaurant";
    static appVersion = '2.31.00';

    static cipher: string = 'KPdzpXFGaUkAFHwj5FBy82B6'; 

    static isCanuucan = 'false';

    static pickupTypes = [ 'DELIVERYTYPE02', 'DELIVERYTYPE07' ];
    static zendeskAccountUrl = 'moduurnhelp.zendesk.com';
    static zendeskUserField=1500009211882;
    static zendeskUserFormId= 1500002580982;
    
    constructor() { }
}
